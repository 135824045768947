<template>
<b-container>
    <div class="mb-4" style="background:#E7EAEE;padding:2px;border-radius:5px">
    <b-tabs pills fill>
        <b-tab title="Practices" @click="setActiveTab(0)"></b-tab>
        <b-tab title="Codes" @click="setActiveTab(1)"></b-tab>
        <b-tab title="Payers" @click="setActiveTab(2)"></b-tab>
    </b-tabs>
    </div>
    <b-alert
        v-model="showAlert"
        variant="danger"
        style="margin-left:20px"
        dismissible>
        <b>{{alertMessage}}</b>
    </b-alert>
    <div>
        <div class="d-flex justify-content-between">
            <div v-if="activeTab == 0">
                <b-row class="mb-4">
                    <b-col sm="12" :md="7">
                        <div class="d-flex justify-content-between align-items-center">
                        <b-input-group class="search-group">
                            <b-input-group-prepend>
                                <span class="input-group-text"><b-icon icon="search"></b-icon></span>
                            </b-input-group-prepend>
                            <b-form-input
                                @keyup="debouncePractices"
                                @search="debouncePractices"
                                class="worker-search-input pl-1"
                                type="search" v-model="search"
                                placeholder="Try typing a practice name"/>
                        </b-input-group>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-else-if="activeTab == 1">
                <b-row class="mb-4">
                    <b-col sm="12" :md="7">
                        <div class="d-flex justify-content-between align-items-center">
                        <b-input-group class="search-group">
                            <b-input-group-prepend>
                                <span class="input-group-text"><b-icon icon="search"></b-icon></span>
                            </b-input-group-prepend>
                            <b-form-input
                                @keyup="debounceCodes"
                                @search="debounceCodes"
                                class="worker-search-input pl-1"
                                type="search" v-model="search"
                                placeholder="Try typing a cpt code"/>
                        </b-input-group>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div v-else-if="activeTab == 2">
                <b-row class="mb-4">
                    <b-col sm="12" :md="7">
                        <div class="d-flex justify-content-between align-items-center">
                        <b-input-group class="search-group">
                            <b-input-group-prepend>
                                <span class="input-group-text"><b-icon icon="search"></b-icon></span>
                            </b-input-group-prepend>
                            <b-form-input
                                @keyup="debouncePayers"
                                @search="debouncePayers"
                                class="worker-search-input pl-1"
                                type="search" v-model="search"
                                placeholder="Try typing a payer name"/>
                        </b-input-group>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div>
                <b-button v-b-modal.worker-assignments-modal class="avo-primary-btn">Update assignments</b-button>
                <worker-assignments-update :worker="worker"
                        :practicesOptions="practicesOptions" :payersOptions="payersOptions"
                        :payersGroupsOptions="payersGroupsOptions"
                        @updated="refreshTables()"/>
            </div>
        </div>

        <div v-if="activeTab == 0">
            <div class="avo-table-lrg-wrapper">
            <b-table id="worker-practices-table"
                :sort-desc.sync="sortDesc"
                :per-page="perPage" 
                :current-page="currentPage" 
                :items="getWorkerPractices"
                :fields="practicesFields"
                :busy="tableLoading"
                :sticky-header="'90vh'"
                class="avo-table-lrg"
                primary-key="practice_id"
                ref="practicesTable"
                show-empty
                :emptyHtml="emptyTableListMessage('practices')"
                striped>

                <template #table-busy>
                <div class="text-center text-secondary my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Fetching practices for you...</strong>
                </div>
                </template>
                
                <template #cell(practice)="data">
                <div>{{ data.item.name || "-" }} </div>
                </template>
                
                <template #cell(address)="data">
                    {{ data.item.address || "-" }}
                </template>
                
                <template #cell(group_npi)="data">
                <div>{{ data.item.group_npi || "-" }}</div>
                </template>

                <template #cell(tax_id)="data">
                <div>{{ data.item.tax_id || "-" }}</div>
                </template>

                <template #cell(assigned_on)="data">
                    <div>{{formatDate(data.item.assigned_on)}}</div>
                </template>

            </b-table>
            </div>
            <b-pagination
                v-if="practices.length > 0"
                align="center"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="practicesTable">
            </b-pagination>
        </div>
        <div v-if="activeTab == 1">
            <div class="avo-table-lrg-wrapper">
            <b-table id="worker-codes-table"
                :sort-desc.sync="sortDesc"
                :per-page="perPage" 
                :current-page="currentPage" 
                :items="getWorkerCodes"
                :fields="codesFields"
                :busy="tableLoading"
                :sticky-header="'90vh'"
                class="avo-table-lrg"
                primary-key="cpt_code"
                ref="codesTable"
                show-empty
                :emptyHtml="emptyTableListMessage('codes')"
                striped>

                <template #table-busy>
                <div class="text-center text-secondary my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Fetching codes for you...</strong>
                </div>
                </template>
                
                <template #cell(code)="data">
                <div>{{ data.item.code  || "-"}} </div>
                </template>
                
                <template #cell(description)="data">
                    {{ data.item.long || "-" }}
                </template>

                <template #cell(assigned_on)="data">
                    <div>{{formatDate(data.item.assigned_on)}}</div>
                </template>

            </b-table>
            </div>
            <b-pagination
                v-if="codes.length > 0"
                align="center"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="codesTable">
            </b-pagination>
        </div>

        <div v-if="activeTab == 2">
            <div class="avo-table-lrg-wrapper">
            <b-table id="worker-payers-table"
                :sort-desc.sync="sortDesc"
                :per-page="perPage" 
                :current-page="currentPage" 
                :items="getWorkerPayers"
                :fields="payersFields"
                :busy="tableLoading"
                :sticky-header="'90vh'"
                class="avo-table-lrg"
                primary-key="payer_id"
                ref="payersTable"
                show-empty
                :emptyHtml="emptyTableListMessage('payers')"
                striped>

                <template #table-busy>
                <div class="text-center text-secondary my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Fetching payers for you...</strong>
                </div>
                </template>
                
                <!--template #cell(payers_id)="data">
                <div><strong>{{ data.value }}</strong></div>
                </template-->
                
                <template #cell(payer)="data">
                <div>{{ data.item.name || "-" }} </div>
                </template>

                <template #cell(website)="data">
                    {{ data.item.website || "-" }}
                </template>

                <template #cell(phone)="data">
                    {{ data.item.telephone || "-" }}
                </template>

                <template #cell(fax)="data">
                    {{ data.item.fax || "-" }}
                </template>

                <template #cell(address)="data">
                    {{ data.item.full_address || "-" }}
                </template>

                <template #cell(assigned_on)="data">
                    <div>{{formatDate(data.item.assigned_on)}}</div>
                </template>

            </b-table>
            </div>
            <b-pagination
                v-if="payers.length > 0"
                align="center"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="payersTable">
            </b-pagination>
        </div>
    </div>
</b-container>
</template>

<script>
import { make_url, authenticated_request, debounce, formatDateShort as formatDate, alertsMixin } from '../../common.js'
import WorkerAssignmentsUpdate from './WorkerAssignmentsUpdate.vue'

export default {
    name: 'WorkerAssignments',
    mixins: [alertsMixin],
    components: {
        WorkerAssignmentsUpdate
    },
    data(){
        return {
            activeTab: 0,
            practices: [],
            practicesOptions: [],
            practicesFields: [
                'practice',
                {
                    label: 'Address',
                    key: 'full_address'
                },
                {
                    label: 'Group NPI',
                    key : 'group_npi'
                },
                {
                    label: 'Tax ID',
                    key : 'tax_id'
                },
                { 
                    label: 'Assigned On',
                    key: 'assigned_on',
                    sortable: true,
                    thClass: 'right-align-col',
                    tdClass: 'right-align-col'
                },
            ],

            codes: [],
            codesFields: [
                'code',
                'description',
                { 
                    label: 'Assigned On',
                    key: 'assigned_on',
                    sortable: true,
                    thClass: 'right-align-col',
                    tdClass: 'right-align-col'
                },
            ],

            payers: [],
            payersOptions: [],
            payersFields: [
                'payer',
                'website',
                'telephone',
                'fax',
                'address',
                { 
                    label: 'Assigned On',
                    key: 'assigned_on',
                    sortable: true,
                    thClass: 'right-align-col',
                    tdClass: 'right-align-col'
                },
            ],

            payersGroupsOptions: [],

            perPage: 10,
            currentPage: 1,
            search: "",
            rows: 0,
            sortDesc: null,
            tableLoading: null,
        }
    },
    methods: {
        setActiveTab: function(tab){
            if (this.activeTab != tab){
                this.currentPage = 1;
                this.search = "";
                this.rows = 0;
                this.sortDesc = null;
                this.activeTab = tab;
            }
        },
        emptyTableListMessage: function(items) {
            if (this.tableLoading) {
                return "Fetching " + items + " for you ..."
            } else {
                return "No " + items + " match the selected filters"
            }
        },
        getWorkerPractices(){
            var params = {
                search: this.search,
                desc: this.sortDesc,
                per_page: this.perPage,
                page: this.currentPage,
            }
            const url = make_url("/workers/" + this.worker.worker_id + "/practices/data");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: params
            }).then(function(response){
                const items = response.data.practices;
                vm.practices = items;
                vm.rows = response.data.pagination.total;
                vm.perPage = response.data.pagination.per_page;

                return items || [];
            }).catch(function(error){
                vm.setShowAlert(true, error);
            }).finally(()=>{
                vm.tableLoading = false;
            });
            return promise;
        },
        searchWorkerPractices: function() {
            this.tableLoading = (this.tableLoading == false) ? false : true;
            if (this.search.length > 2 || this.search.length == 0) {
                this.currentPage = 1;
                this.getWorkerPractices();
                this.$refs.practicesTable.refresh();
            }
        },
        getWorkerCodes(){
            var params = {
                search: this.search,
                desc: this.sortDesc,
                per_page: this.perPage,
                page: this.currentPage,
            }
            const url = make_url("/workers/" + this.worker.worker_id + "/codes/data");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: params
            }).then(function(response){
                const items = response.data.codes;
                vm.codes = items;
                vm.rows = response.data.pagination.total;
                vm.perPage = response.data.pagination.per_page;

                return items || [];
            }).catch(function(error){
                vm.setShowAlert(true, error);
            }).finally(()=>{
                vm.tableLoading = false;
            });
            return promise;
        },
        searchWorkerCodes: function() {
            this.tableLoading = (this.tableLoading == false) ? false : true;
            if (this.search.length > 2 || this.search.length == 0) {
                this.currentPage = 1;
                this.getWorkerCodes();
                this.$refs.codesTable.refresh();
            }
        },
        getWorkerPayers(){
            var params = {
                search: this.search,
                desc: this.sortDesc,
                per_page: this.perPage,
                page: this.currentPage,
            }
            const url = make_url("/workers/" + this.worker.worker_id + "/payers/data");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: params
            }).then(function(response){
                const items = response.data.payers;
                vm.payers = items;
                vm.rows = response.data.pagination.total;
                vm.perPage = response.data.pagination.per_page;

                return items || [];
            }).catch(function(error){
                vm.setShowAlert(true, error);
            }).finally(()=>{
                vm.tableLoading = false;
            });
            return promise;
        },
        searchWorkerPayers: function() {
            this.tableLoading = (this.tableLoading == false) ? false : true;
            if (this.search.length > 2 || this.search.length == 0) {
                this.currentPage = 1;
                this.getWorkerPayers();
                this.$refs.payersTable.refresh();
            }
        },
        formatDate,
        refreshTables(){
            if (this.activeTab == 0){
                this.$refs.practicesTable.refresh();
            }
            else if (this.activeTab == 1){
                this.$refs.codesTable.refresh();
            }
            else if (this.activeTab == 2){
                this.$refs.payersTable.refresh();
            }
        },
    },
    computed: {
        worker: function(){
            return this.$store.state.workers.selected_worker;
        }
    },
    watch: {
    },
    created(){
      this.getWorkerPractices();
      this.debouncePractices = debounce(this.searchWorkerPractices, 900);
      this.debounceCodes = debounce(this.searchWorkerCodes, 900);
      this.debouncePayers = debounce(this.searchWorkerPayers, 900);
    },
    mounted(){
        const vm = this;
        // Get available payers to assign
        authenticated_request({
            method: "get",
            url: make_url("/payers"),
        }).then(function(response){
            const payersData = response.data.payers;
            for (var index = 0; index < payersData.length; index++){
            vm.payersOptions.push({value: payersData[index].payer_id, text: payersData[index].name});
            }
        });
        // Get available payer groups to assign
        authenticated_request({
            method: "get",
            url: make_url("/payers/groups"),
        }).then(function(response){
            const payersGroupsData = response.data.payers_groups;
            for (var index = 0; index < payersGroupsData.length; index++){
            vm.payersGroupsOptions.push({value: payersGroupsData[index].payer_group_id, text: payersGroupsData[index].name});
            }
        });
        // Get available practices to assign
        authenticated_request({
            method: "get",
            url: make_url("/practices"),
        }).then(function(response){
            const practiceData = response.data.practices;
            for (var index = 0; index < practiceData.length; index++){
            vm.practicesOptions.push({value: practiceData[index].practice_id, text: practiceData[index].name});
            }
        });
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* increasing b-containers max-width for larger screens */
@media only screen and (min-width : 1200px) {
   .container { max-width: 1400px; } 
}

/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    margin-right: 20px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:650px;
    min-width:400px;
}
::v-deep .worker-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .worker-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}

.tabs {
    background: #E7EAEE;
    border-radius:5px;
    color: var(--dark);
    font-size: 14px;
}

::v-deep .nav-pills .nav-link, .nav-pills .show > .nav-link{
    background: #E7EAEE;
    color: var(--dark);
}

::v-deep .nav-pills .nav-link.active, .nav-pills .show > .nav-link{
    background: white;
    color: var(--dark);
}

</style>