<template>
  <Bar
    :chart-data="chartData"
    :chart-options="options"
    :styles="styles"
  />
</template>


<script>
import "chart.js/auto";
import { Bar } from "vue-chartjs/legacy";

export default {
  props: ["chartData", "styles"],
  components: {
    Bar,
  },
  computed: {
    options: function () {
      return {
        borderRadius: 10,
        indexAxis: "y",

        maintainAspectRatio: false,
        responsive: true,

        plugins: {
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.xLabel;
              },
            },
          },
        },
        scales: {
          y: {
              grid: {
                display: false,
              },
            },
        },
      };
    },
  },
};
</script>

