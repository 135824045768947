<template>
<div>
    <b-row class="mb-4">
        <b-col sm="12" :md="7">
            <div class="d-flex justify-content-between align-items-center">
            <b-input-group class="search-group">
                <b-input-group-prepend>
                    <span class="input-group-text"><b-icon icon="search"></b-icon></span>
                </b-input-group-prepend>
                <b-form-input
                    @keyup="debounceClaims"
                    @search="debounceClaims"
                    class="claim-search-input pl-1"
                    type="search" v-model="search.terms"
                    placeholder="Try typing a provider or patient's name"/>                
                <b-input-group-append >
                    <span class="divider"></span>
                    <div>
                        <b-dropdown class="dropdown flag-dropdown">
                            <template #button-content>
                                {{flagInputLabel}}
                            </template>
                            <b-dropdown-form>
                                <div style="font-size:20px;font-weight:300;margin-bottom:15px;">Substatuses</div>
                                <b-form-checkbox 
                                  class="mb-2"
                                  v-model="doSelectAllSubstatuses" 
                                  @change="selectAllSubstatuses">
                                    <span class="avo-text-bold">Select/Deselect All</span>
                                </b-form-checkbox>
                                <b-form-checkbox-group
                                    v-model="search.substatuses"
                                    :options="substatuses"
                                    @change="debounceClaims">
                                </b-form-checkbox-group>
                            </b-dropdown-form>
                        </b-dropdown>
                    </div>
                    <span class="divider"></span>
                    <b-dropdown class="dropdown filter-dropdown" :boundary="'window'">
                        <template #button-content>
                        <b-icon-funnel class="mr-2" />{{filterInputLabel}}
                        </template>
                        <b-dropdown-form class="d-flex flex-column align-items-start">
                        <span style="font-size:20px;font-weight:300">Filters</span>
                        <div class="selecter-label">Age of Claim</div>
                            <b-form-checkbox-group
                            v-model="search.ages"
                            :options="age_ranges"
                            buttons
                            button-variant="outline-primary"
                            class="claim-age-btn-group"
                            id="search-ages">
                            </b-form-checkbox-group>
                        <br />
                        
                        <div v-if="!issues" class="selecter-label">Status of Claim</div>
                        <multiselect v-if="!issues"
                            v-model="search.states"
                            :hide-selected="true"
                            :options="states"
                            track-by="state" 
                            label="label"
                            :multiple="true"
                            :show-labels="false"
                            placeholder="-- Select an Option --">
                        </multiselect>
                        
                        <div class="selecter-label">Payers</div>
                        <payer-multiselect
                          v-model="search.payers"
                          :multiple="true"
                        />
                        
                        <div class="selecter-label">Practice</div>
                        <practice-multiselect
                          v-model="search.practices"
                          :multiple="true"
                        />
                        
                        <div class="selecter-label">Provider</div>
                        <provider-multiselect
                          v-model="search.providers"
                          :multiple="true"
                        />
                        
                        <div class="d-flex justify-content-end mt-4">
                            <b-button @click="clearFilters" variant="outline-primary" class="avo-basic-btn">Clear Filters</b-button>
                            <b-button @click="searchWorkerClaims" variant="outline-primary" class="avo-primary-btn ml-3">Apply</b-button>
                        </div>
                        </b-dropdown-form>
                    </b-dropdown>
                </b-input-group-append>
            </b-input-group>
            </div>
        </b-col>
    </b-row>

    <div>
    <div class="avo-table-lrg-wrapper">
    <b-table id="worker-claims-table" 
        :sort-by.sync="sortBy" 
        :sort-desc.sync="sortDesc"
        :per-page="perPage" 
        :current-page="currentPage" 
        :items="getWorkerClaims"
        :fields="claimsFields"
        :busy="tableLoading"
        :selectable="true"
        select-mode="single"
        @row-clicked="onSelect"
        :sticky-header="'90vh'"
        class="avo-table-lrg"
        primary-key="claim_id"
        ref="table"
        show-empty
        emptyText="No claims match the selected filters"
        striped>

        <template #table-busy>
        <div class="text-center text-secondary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Fetching claims for you ...</strong>
        </div>
        </template>
        
        <template #cell(claim_id)="data">
        <div><strong>{{ data.value }}</strong></div>
        <div class="d-flex flex-column">
            <div class="d-flex align-items-center mb-1">
                <div :class="['status-icon',  data.item.state.short]"></div>
                <div class="claim-state">{{ data.item.state.label }}</div>
            </div>
            <div v-if="data.item.substatus_1" class="avo-text-light-small">
            - {{ substatuses[data.item.substatus_1]}}</div>
            <div v-if="data.item.substatus_2" class="avo-text-light-small">
            - {{ substatuses[data.item.substatus_2]}}</div>
            <div v-if="data.item.substatus_3" class="avo-text-light-small">
            - {{ substatuses[data.item.substatus_3]}}</div>
        </div>
        <!-- <div v-if="issues" class="claim-flag"><b-icon icon="exclamation-circle" class="mr-1"></b-icon>{{ data.item.flag }}</div> -->
        </template>
        
        <template #cell(patient)="data">
        <div>{{ data.item.patient_name }} </div>
        <div class="subtext" v-if="data.item.patient_id">Patient ID: {{ data.item.patient_id }}</div>
        </template>
        
        <template #cell(assigned)="data">
            <span v-if="!issues">
            <span v-if="data.item.worker_name">
            {{ data.item.worker_name }}
            </span>
            <span v-else>
            --
            </span>
            </span>
            <span v-else>
            <span v-if="data.item.issues_worker_name">
            {{ data.item.issues_worker_name }}
            </span>
            <span v-else>
            --
            </span>
            </span>
        </template>
        
        <template #cell(payer)="data">
        <div>{{ data.item.payer_name }}</div>
        </template>

        <template #cell(practice)="data">
          <div>{{ data.item.practice.name || '--' }}</div>
        </template>
        
        <template #cell(provider)="data">
        <div>
            {{ data.item.provider_name }}<span v-if="data.value.suffix">, {{ data.value.suffix }}</span>
        </div>
        <div class="subtext d-flex flex-column">
            <div v-if="data.item.pracitce_location">{{ data.item.practice_location.name }}</div>
            <div v-if="data.item.practice_location_address">{{ formatAddress(data.item.practice_location_address) }}</div>
        </div>
        </template>

        <template #cell(age)="data">
        <div>{{data.value}} Days</div> 
        </template>

        <template #cell(balance)="data">
            <div>${{ data.item.balance }}</div>
            <div class="subtext">
            <div>${{ data.item.invoiced }}</div>
            <div>Claim Amount</div>
            </div>
        </template>

        <template #cell(timely_days_left)="data">
          <span v-if="data.value">
            <div :class="['timely-filing-icon', timelyFilingClass(data.value)]">
            {{ data.value }}
            </div>
            <div style="font-size:12px;color:var(--gray);">Days Left</div>
          </span>
          <span v-else>
            --
          </span>
        </template>
    </b-table>
    </div>
    <b-pagination
        v-if="claims.length > 0"
        align="center"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="claims-table">
    </b-pagination>
   </div>
</div>
</template>

<script>
import { alertsMixin, make_url, authenticated_request, debounce, formatDateShort as formatDate, formatAddressShort as formatAddress } from '../../common.js'
import Multiselect from 'vue-multiselect'
import PayerMultiselect from '../multiselects/PayerMultiselect.vue'
import PracticeMultiselect from '../multiselects/PracticeMultiselect.vue'
import ProviderMultiselect from '../multiselects/ProviderMultiselect.vue'

export default {
  name: 'WorkerClaimsList',
  components: { Multiselect,
                PayerMultiselect,
                PracticeMultiselect,
                ProviderMultiselect,
  },
  mixins: { alertsMixin },
  props: ['issues'],

  data(){
      return {
        // data for search group
          search: {
              terms: "",
              substatuses: [],
              ages: [],
              states: [],
              payers: [],
              providers: [],
              workers: [],
              practices: []
          },
          doSelectAllSubstatuses: false,
          age_ranges: [
              {text: "0-30 days", value:"0,30"},
              {text: "31-60 days", value:"31,60"},
              {text: "61-90 days", value:"61,90"},
              {text: "91-120 days", value:"91,120"},
              {text: "120+ days", value:"121,9999"}
          ],
          states: [],
          substatuses: [],

        // data for table
          claims: [],
          perPage: 20,
          currentPage: 1,
          rows: 0,
          sortBy: null,
          sortDesc: false,
          tableLoading: null,
      }
  },
  methods: {
    clearFilters: function(){
        this.search.terms = "";
        this.search.ages = [];
        this.search.states = [];
        // this.search.flags = [];
        this.search.substatuses = [];
        this.search.payers = [];
        this.search.providers = [];
        this.search.practices = [];
        this.search.workers =  [];
        this.getWorkerClaims();
        this.$refs.table.refresh();
    },
    selectAllSubstatuses() {
        if (this.doSelectAllSubstatuses) {
            this.search.substatuses = Object.keys(this.substatuses).map(function(key) {
                return key;
            });
        } else {
            this.search.substatuses = [];
        }
            this.getWorkerClaims();
            this.$refs.table.refresh();
    },
    getFilterOptions: function(){
        const url = make_url("/reports/claims/filters");
        const vm = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            const items = response.data;
            vm.states = [];
            vm.substatuses = items.substatuses;
            
            //remove flagged state as these only apppear under the 'Issues' tab
            for (var i = 0; i < items.states.length; i++){
                if (items.states[i].label != "Claim Flagged"){
                    vm.states.push(items.states[i]);
                }
            }
            return items || [];
        });
        return promise;
    },
    searchWorkerClaims: function() {
        this.tableLoading = (this.tableLoading == false) ? false : true;
        if (this.search.terms.length > 2 || this.search.terms.length == 0) {
            this.currentPage = 1;
            this.getWorkerClaims();
            this.$refs.table.refresh();
        }
    },  

//---------- methods for table ----------
    onSelect: function(item) {
        this.$router.push({ name: 'claim-view', params: { initialClaimId: item.claim_id } });
    },
    getWorkerClaims: function(){
      // If table has already finished loading, this is an update
        let params = this.formattedParams;
        
        const url = make_url("/reports/claims");
        if (this.issues){
            params.issues_claims = true;
        } else {
            params.issues_claims = false;
        }
        params.worker_ids = this.worker.worker_id.toString()
        if(this.sortBy){
            params.sort = this.sortBy + ":" + (this.sortDesc?"desc":"asc")
        }
        
        const vm = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
            params: params
        }).then(function(response){
            const items = response.data.claims;
            vm.claims = items;
            vm.rows = response.data.pagination.total;
            vm.perPage = response.data.pagination.per_page;
            return items || [];
        }).catch(function(error){
            vm.setShowAlert(true, error);
        }).finally(()=>{
            vm.tableLoading = false;
        });
        return promise;
    },
    timelyFilingClass: function(daysLeft) {
        var filingClass = '';
        filingClass = daysLeft < 30 ? 'filing-red': daysLeft < 60 ? 'filing-yellow' : 'filing-blue';
        return filingClass;
    },
    formatAddress,
    formatDate,
  },
  computed: {
      worker: function(){
        return this.$store.state.workers.selected_worker;
      },
      flagInputLabel: function(){
          if(this.search.substatuses.length == 0) {
               return "Filter Sub-statuses";
          } else {
              return this.search.substatuses.length + " Substatus(es)"
          }
      },
      filterInputLabel: function(){
          const count = this.filterCount;
          if(count == 0) {
               return "More Filters";
          } else {
               return count + " Filter(s)";
          }
      },
      filterCount: function() {
          var count = 0;
          for(const filter in this.search){
              if(this.search[filter].length && filter !== "terms" && filter !== "substatuses"){
                  count += 1;
              }
          }
          return count;
      },
      formattedParams: function() {
          var tabStates = this.search.states;
          if (!this.issues) {
              if (tabStates.length == 0){
                    tabStates = "-1,0,1,2,3,4,5"
              } else {
                    const index = tabStates.indexOf(6);
                    if (index > -1) {
                        tabStates.splice(index, 1);
                    }
                    tabStates = tabStates.map(obj => obj.state).join()
              }
          } else {
              tabStates = tabStates.map(obj => obj.state).join()
          }
          
          var params = {
              search_terms: this.search.terms,
              substatuses: this.search.substatuses.join(),
              age_ranges: this.search.ages.join(),
              states:  tabStates,
              worker_ids: this.search.workers.map(obj => obj.worker_id).join(),
              practice_ids: this.search.practices.map(obj => obj.practice_id).join(),
              payer_ids: this.search.payers.map(obj => obj.payer_id).join(),
              provider_ids: this.search.providers.map(obj => obj.provider_id).join(),
              per_page: this.perPage,
              page: this.currentPage,
          }
          return params
      },
      claimsFields: function(){
          return [
            {
              label: 'Claim ID',
              key: 'claim_id',
              tdClass: 'claim-id-col'
            },
            'patient',
            'assigned',
            'payer',
            'practice',
            'provider',
            {
              key: 'age',
              sortable: true,
              thClass: 'right-align-col',
              tdClass: 'right-align-col'
            },
            {
              key: 'balance',
              sortable: true,
              thClass: 'right-align-col',
              tdClass: 'right-align-col'
            },
            { 
              label: 'Timely Filing',
              key: 'timely_days_left',
              sortable: true,
              thClass: 'right-align-col',
              tdClass: 'right-align-col'
            }
          ];
      },
  },
  watch: {
    issues: function(){
      this.$refs.table.refresh();
    },
  },
  created() {
      this.getWorkerClaims();
      this.debounceClaims = debounce(this.searchWorkerClaims, 900);
  },
  mounted() {
      this.getFilterOptions();
  }
}
</script>

<style scoped>
/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    margin-right: 20px;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:650px;
}
::v-deep .claim-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .claim-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
.divider {
    display: inline-block;
    width: 0;
    height: 20px;
    margin: auto;
    border-right: 1.8px solid #D2D8E2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
    padding: 20px 30px 30px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
    width:100.3%;
    right: -1px !important;
    left: auto !important;
    top:45px !important;
    transform: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .flag-dropdown .dropdown-menu {
    width: 270px;
    padding: 10px;
    left: -1px !important;
    top:48px !important;
    transform: none !important;
    font-size: 14px;
    font-weight: 500;
    color: #64728C;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
::v-deep .flag-dropdown .b-dropdown-form {
    padding: 0.2rem 0.8rem;
}

/* Styling for claim age button group */
.claim-age-btn-group {
    min-height:40px;
}
::v-deep .claim-age-btn-group .btn-outline-primary {
    border: 1px solid #C9D2DF;
    padding-top: 9px;
    font-size: 14px;
    color: var(--gray);
}
::v-deep .claim-age-btn-group .btn-outline-primary:hover {
    background-color: #F2F6FC;
    color: var(--gray-800);
}
::v-deep .claim-age-btn-group .btn-outline-primary:active,
::v-deep .claim-age-btn-group .btn-outline-primary.active {
    border: 1px solid var(--blue) !important;
    background-color: #F2F6FC !important;
    color: var(--dark);
}
::v-deep .claim-age-btn-group .btn-outline-primary:focus,
::v-deep .claim-age-btn-group .btn-outline-primary.focus {
    box-shadow: none !important;
}

.balance-text {
    font-size: 20px;
    font-weight: 300;
}
.avo-table-lrg .subtext {
    font-size: 12px;
    color: var(--gray);
    margin-top:7px;
}
.avo-table-lrg .claim-flag {
    font-size: 12px;
    color: var(--red);
    margin-top:7px;
}
.avo-table-lrg .claim-state {
    font-size: 12px;
    color: var(--gray-800);
    /* margin-top:7px; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.avo-table-lrg::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.avo-table-lrg {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Styles for timely filing icons */
.timely-filing-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    width: 47px;
    height: 38px;
    border-radius: 5px; 
    font-size: 18px;
    font-weight: 700;
    background: var(--gray-light) 0% 0% no-repeat padding-box;
    border: 1px solid black;
}
.filing-red {
  background: #FFF3EB 0% 0% no-repeat padding-box;
  border: 1px solid #FD4739;
  color: #FD4739;
}
.filing-yellow {
  background: #FDF9E7 0% 0% no-repeat padding-box;
  border: 1px solid #EEC05A;
  color: #E7A33A;
}
.filing-blue {
  background: #E0EBF3 0% 0% no-repeat padding-box;
  border: 1px solid #2473AB;
  color: #2473AB;
}
</style>