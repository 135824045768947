<template>
  <Bar :chart-data="chartData" :chart-options="options" :styles="styles" />
</template>

<script>
import "chart.js/auto";
import { Bar } from "vue-chartjs/legacy";

export default {
  name: "BarChart",
  components: { Bar },
  props: ["chartData", "styles"],
  computed: {
    options: function () {
      return {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.yLabel;
              },
            },
          },
        },
        barThickness: 36,
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
          },
        },
      };
    },
  },
};
</script>