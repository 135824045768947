<template>
  <LineChart
    :chart-data="chartData"
    :chart-options="options"
    :styles="styles"
  />
</template>

<script>
import "chart.js/auto";
import { Line as LineChart } from "vue-chartjs/legacy";

export default {
  name: "ClaimsWorkedLineChart",
  props: ["chartData", "styles"],
  components: { LineChart },
  data() {
    return {
      options: {
        maintainAspectRatio: false,
        responsive: true,

        plugins: {
          legend: {
            display: false,
          },
        },
      },
    };
  },
};
</script>