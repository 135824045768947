<template>
<div v-if="worker">
    <b-row class="my-5 ml-2 mr-4">
        <b-col cols="3" class="d-flex align-items-center pr-0">
            <b-avatar class="mr-3" style="width:64px;height:64px;" />
            <div class="d-flex flex-column">
                <div style="color:#010C14;font-size:28px;">{{worker.name}}</div>
                <div class="avo-text-normal-grey">Claims Worker</div>
            </div>
        </b-col>
        <b-col cols="9" class="d-flex justify-content-end pl-0"
            v-if="worker.assignments != undefined">
            <div class="mt-2 mr-2" style="padding-left:10px;border-left:4px solid var(--blue)"></div>
            <div class="d-flex flex-column">
                <div>
                    <div class="stat-text">
                        {{worker.assignments}}
                    </div>
                    <div class="avo-text-normal-grey">Assigned Claims</div>
                </div>
            </div>
            <div class="mt-2 ml-4 mr-2" style="padding-left:10px;border-left:4px solid #FD4739"></div>
            <div class="d-flex flex-column">
                <div class="stat-text">
                    {{worker.issues_claims ? worker.issues_claims : 0}}
                </div>
                <div class="avo-text-normal-grey">Current Issues</div>
            </div>
            <div class="mt-2 ml-4 mr-2" style="padding-left:10px;border-left:4px solid #41B0A5"></div>
            <div class="d-flex flex-column">
                <div class="stat-text">
                    {{worker.practice_assignments}}
                </div>
                <div class="avo-text-normal-grey">Assigned Practices</div>
            </div>
            <div class="mt-2 ml-4 mr-2" style="padding-left:10px;border-left:4px solid #EEC05A"></div>
            <div class="d-flex flex-column">
                <div class="stat-text">
                    {{worker.code_assignments}}
                </div>
                <div class="avo-text-normal-grey">Assigned Codes</div>
            </div>
            <div class="mt-2 ml-4 mr-2" style="padding-left:10px;border-left:4px solid #AF3ECA"></div>
            <div class="d-flex flex-column">
                <div class="stat-text">
                    {{worker.payer_assignments}}
                </div>
                <div class="avo-text-normal-grey">Assigned Payers</div>
            </div>
        </b-col>
    </b-row>
    <div class="mt-4">
        <b-nav tabs class="nav-section">
            <b-nav-item :active="activeTab == 'WorkerSummary'" @click="setActiveTab('WorkerSummary')">Summary</b-nav-item>
            <b-nav-item :active="activeTab == 'WorkerClaims'" @click="setActiveTab('WorkerClaims')">Claims</b-nav-item>
            <b-nav-item :active="activeTab == 'WorkerIssues'" @click="setActiveTab('WorkerIssues')">Issues</b-nav-item>
            <b-nav-item :active="activeTab == 'WorkerAssigments'" @click="setActiveTab('WorkerAssigments')"
                v-if="hasPermission('edit-worker-assignments')">
                Assigned
            </b-nav-item>
            <b-nav-item :active="activeTab == 'WorkerTasks'" @click="setActiveTab('WorkerTasks')">Tasks</b-nav-item>
            <b-nav-item :active="activeTab == 'WorkerWorklog'" @click="setActiveTab('WorkerWorklog')">Work Log</b-nav-item>
        </b-nav>
        <b-container>
            <keep-alive>
                <component class="mt-4" :is="activeTabComponent" v-bind="activeTabProps" />
            </keep-alive>
        </b-container>
    </div>
</div>
</template>

<script>
import WorkerSummary from './WorkerSummary.vue'
import WorkerClaimsList from './WorkerClaimsList.vue'
import WorkerAssigments from './WorkerAssignments.vue'
import TaskList from '../tasks/TaskList.vue'
import WorkLog from '../worklog/WorkLog.vue'
import { permissionsMixin } from '../../common'

export default {
    name: 'WorkerView',
    mixins: [ permissionsMixin ],
    components: {
        WorkerSummary,
        WorkerClaimsList,
        WorkerAssigments,
        TaskList,
        WorkLog
    },
    data() {
        return {
            comp: function (activeTab) {
                const tabComponents = {
                    'WorkerSummary': { component: 'WorkerSummary', props: { } },
                    'WorkerClaims': { component: 'WorkerClaimsList', props: { } },
                    'WorkerIssues': { component: 'WorkerClaimsList', props: { issues: true } },
                    'WorkerAssigments': { component: 'WorkerAssigments', props: { } },
                    'WorkerTasks': { component: 'TaskList', props: { workerId: this.worker.worker_id, screen: true } },
                    'WorkerWorklog': { component: 'WorkLog', props: { 'worker-id': this.worker.worker_id, embedded: false, showClaimId: true, hideLogWorkButton: true } },
                }
                return tabComponents[activeTab]
            }

        }
    },
    methods: {
        // For tab navigation
        setActiveTab(component){
            this.$store.commit("workers/setActiveTab", component);
        },
    },
    computed: {
        worker: function(){
            return this.$store.state.workers.selected_worker;
        },
        activeTab: function(){
            return this.$store.state.workers.active_tab;
        },
        activeTabComponent: function(){
            return this.comp(this.$store.state.workers.active_tab).component;
        },
        activeTabProps: function(){
            return this.comp(this.$store.state.workers.active_tab).props;
        },
    },
    mounted() {
        if (!this.worker) {
            const worker_id = localStorage.getItem('worker_id');
            const user_full_name = localStorage.getItem('user_full_name');
            this.$store.commit("workers/selectWorker", 
                { 
                "name":user_full_name,
                "worker_id":worker_id,
                } 
            )
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-section {
    border-bottom: 1px solid #E6EEF9;
    margin-left: -15px;
    margin-right: -15px;
    padding-right: 35px;
    padding-left: 35px;
}
</style>