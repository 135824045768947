<template>
<b-container class="p-0">
    <div style="width:40vh">
        <b-input-group v-if="!practicesLoading" 
            class="search-group mb-4">
            <div class="mb-2">Filter By</div>
            <multiselect
                v-model="selectedPractice"
                :options="practices"
                track-by="practice_id" 
                label="name"
                :show-labels="false"
                placeholder="Select a practice">
            </multiselect>
        </b-input-group>
        <div v-else>
            <b-spinner></b-spinner>
        </div>
    </div>
    <div class="d-flex my-5">
        <div class="stat-box mr-3">
            <b-iconstack class="mr-4 ml-1" style="color:#3591EB;">
                <b-icon icon="circle-fill" style="color:#F3F8FD" scale="2.2"></b-icon>
                <b-icon icon="circle" scale="2.2"></b-icon>
                <b-icon icon="hourglass" scale="1"></b-icon>
            </b-iconstack>
            <div class="d-flex flex-column justify-content-center">
                <div class="avo-text-light neg-margin">Avg. Time to Close</div>
                <div class="stat-text">{{stats.avgTimeToClose}} <span class="stat-unit-text">days</span></div>
            </div>
        </div>
        <div class="stat-box mr-3">
            <b-iconstack class="mr-4 ml-1" style="color:#3591EB;">
                <b-icon icon="circle-fill" style="color:#F3F8FD" scale="2.2"></b-icon>
                <b-icon icon="circle" scale="2.2"></b-icon>
                <b-icon icon="check2-square" scale="1"></b-icon>
            </b-iconstack>
            <div class="d-flex flex-column justify-content-center">
                <div class="avo-text-light neg-margin">Avg. Claims Worked</div>
                <div class="stat-text">{{stats.avgClaimsWorked}} <span class="stat-unit-text">per day</span></div>
            </div>
        </div>       
        <div class="stat-box mr-3">
            <div style="position:relative">
                <b-iconstack class="mr-4 ml-1" style="color:#3591EB;">
                    <b-icon icon="circle-fill" style="color:#F3F8FD" scale="2.2"></b-icon>
                    <b-icon icon="circle" scale="2.2"></b-icon>
                </b-iconstack>
                <div style="font-size:18px;color:#3591EB;position:absolute;top:-1px;left:6.5px;">$</div>
            </div>
            <div class="d-flex flex-column justify-content-center">
                <div class="avo-text-light neg-margin">Avg. Payments Collected</div>
                <div class="stat-text">{{stats.avgPaymentsCollected}}%</div>
            </div>
        </div> 
        <div class="stat-box mr-3">
            <b-iconstack class="mr-4 ml-1" style="color:#3591EB;">
                <b-icon icon="circle-fill" style="color:#F3F8FD" scale="2.2"></b-icon>
                <b-icon icon="circle" scale="2.2"></b-icon>
                <b-icon icon="x-octagon" scale="1"></b-icon>
            </b-iconstack>
            <div class="d-flex flex-column justify-content-center">
                <div class="avo-text-light neg-margin">Unresolved Claims</div>
                <div class="stat-text">{{stats.unresolvedClaims}}%</div>
            </div>
        </div> 
        <div class="stat-box">
            <b-iconstack class="mr-4 ml-1" style="color:#3591EB;">
                <b-icon icon="circle-fill" style="color:#F3F8FD" scale="2.2"></b-icon>
                <b-icon icon="circle" scale="2.2"></b-icon>
                <b-icon icon="slash-circle" scale="1"></b-icon>
            </b-iconstack>
            <div class="d-flex flex-column justify-content-center">
                <div class="avo-text-light neg-margin">Claims Denied</div>
                <div class="stat-text">{{stats.deniedClaims}}%</div>
            </div>
        </div>
    </div>
    <b-row class="mb-4">
        <b-col cols="6" class="pr-0">
            <b-overlay :show="claimsWorkedChartLoading">
                <b-card class='summary-card shadow mb-4'>
                    <div class="summary-card-header-txt mb-3">
                        Claims Worked
                    </div>
                    <div class="chart-container" style="height:350px">
                            <claims-worked-line-chart :chartData="claimsWorkedChartData" :styles="{width:'100%', height:'100%', position:'relative'}"></claims-worked-line-chart>
                    </div>
                </b-card>
            </b-overlay>
        </b-col>
        <b-col cols="6" >
            <b-overlay :show="currentIssuesTableLoading">
            <b-card class="summary-card-table shadow mb-4">
                <div class="d-flex flex-row justify-content-between px-3 pb-3">
                    <div class="summary-card-header-txt pl-1">
                        Current Issue Claims
                    </div>
                    <div class="avo-text-light pr-2 mt-1 cursor-pointer" @click="$store.commit('workers/setActiveTab', 'WorkerIssues')">
                        View all Issues <b-icon icon="chevron-right"></b-icon>
                    </div>
                </div>
                <b-table id="pending-claims-table" 
                    :fields="currentIssuesFields()"
                    :items="currentIssuesTableData"
                    class="avo-table-embedded"
                    primary-key="claim_id"
                    ref="issueClaimsTable"
                    show-empty
                    empty-text="No current issue claims"
                    striped>
                    
                    <template #cell(claim_id)="data">
                    <div><strong>{{ data.value }}</strong></div>
                    </template>
                    
                    <template #cell(issue_type)="data">
                    <div>{{ data.item.state.issue }}</div>
                    </template>

                    <template #cell(reason)="data">
                    <div>{{ data.item.last_issue_action ? data.item.last_issue_action.description : "N/A" }}</div>
                    </template>

                    <template #cell(work_status)="data">
                    <div>{{ data.item.last_action_type_label ? data.item.last_action_type_label : 'N/A' }}</div>
                    </template>

                </b-table>
            </b-card>
            </b-overlay>
        </b-col>
    </b-row>
    <b-row class="mb-4">
        <b-col cols="4">
            <b-card class='summary-card mb-4 pl-3'>
                <div class="d-flex align-items-center">
                    <div class="claims-billed-text mr-5">{{stats.claimsBilledCount}}</div>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="avo-medium-text">Claims Billed</div>
                        <div class="avo-text-light">${{stats.claimsBilledAmount}} Value</div>
                    </div>
                </div>
            </b-card>
        </b-col>
        <b-col cols="4">
            <b-card class='summary-card mb-4 pl-3'>
                <div class="d-flex align-items-center">
                    <div class="claims-paid-text mr-5">{{stats.claimsPaidCount}}</div>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="avo-medium-text">Claims Paid</div>
                        <div class="avo-text-light">${{stats.claimsPaidAmount}} Value</div>
                    </div>
                </div>
            </b-card>
        </b-col>
        <b-col cols="4">
            <b-card class='summary-card mb-4 pl-3'>
                <div class="d-flex align-items-center">
                    <div class="claims-outstanding-text mr-5">{{stats.claimsOutstandingCount}}</div>
                    <div class="d-flex flex-column justify-content-center">
                        <div class="avo-medium-text">Claims Oustanding</div>
                        <div class="avo-text-light">${{stats.claimsOutstandingAmount}} Value</div>
                    </div>
                </div>
            </b-card>
        </b-col>
    </b-row>
    <b-row>
        <b-col cols="6" class="pr-0">
            <b-overlay :show="worklogListLoading">
                <b-card class='summary-card shadow mb-4 pr-2'>
                    <div class="d-flex flex-row justify-content-between px-2 pb-3">
                        <div class="summary-card-header-txt">
                            Work Log
                        </div>
                        <div class="avo-text-light pr-1 mt-1 cursor-pointer" @click="$store.commit('workers/setActiveTab', 'WorkerWorklog')">
                            View all <b-icon icon="chevron-right"></b-icon>
                        </div>
                    </div>
                        <work-log :logData="worklogArr" :showClaimId="true" :hideSearchGroup="true" :hidePagination="true" :embedded="true"/>
                </b-card>
            </b-overlay>
            <b-card class='summary-card shadow mb-4 pr-2'>
                <div class="summary-card-header-txt">
                    Claims by Stage
                </div>
                <div class="chart-container" style="height:400px">
                    <div v-if="claimsByStageChartLoading" class="spinner-container">
                        <b-spinner></b-spinner>
                    </div>
                    <horizontal-bar-chart :chartData="claimsByStageChartData" :styles="{width:'100%', height:'100%', position:'relative'}"></horizontal-bar-chart>
                </div>
            </b-card>
            <b-card class='summary-card shadow mb-4' style="height:400px;">
                <div class="d-flex flex-row justify-content-start">
                    <div class="summary-card-header-txt">
                        Age of Claims
                    </div>
                </div>
                <div class="bar-chart-container">
                    <div v-if="claimsByAgeChartLoading" class="spinner-container">
                        <b-spinner></b-spinner>
                    </div>
                    <bar-chart :chartData="claimsByAgeChartData" :styles="{height:'100%', width:'100%', marginRight:'0px'}"></bar-chart>
                </div>
            </b-card>
        </b-col>
        <b-col cols="6">
            <b-overlay :show="topIssuesChartLoading">
                <b-card class='summary-card shadow mb-4 pb-2' >
                    <div class="summary-card-header-txt mb-4">
                        Top Claim Issues
                    </div>
                    <b-row>
                        <b-col cols="6" class="pr-0">
                            <div class="chart-container">
                                <donut-chart
                                    :chartData="topIssuesChartData"
                                    :loaded="!topIssuesChartLoading"
                                    :hideLegend="true"
                                    :cutoutPercent="55"
                                    :styles="{width:'80%', position:'relative'}"></donut-chart>
                                <div v-if="!topIssuesChartLoading" class="absolute-centering">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                        <div v-if="totalIssuesClaims != 0" style="color:#38455D;font-size:20px;font-weight:500">{{totalIssuesClaims.toLocaleString()}}</div>
                                        <div v-else style="color:#38455D;font-size:18px">No Issues</div>
                                        <div v-if="totalIssuesClaims != 0" class="small-head-txt">Total</div>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="6">
                            <!--  TODO: make this into it's own pseudo-graph component? -->
                            <div v-if="!topIssuesChartLoading" class="d-flex flex-column justify-content-center h-100 pr-5">
                                <div class="d-flex flex-column mb-3">
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="small-head-txt">Bad Insurance ID</div>
                                        <div class="small-head-txt-bold">{{topIssuesChartData.datasets[0].data[0] ? topIssuesChartData.datasets[0].data[0].toLocaleString() : '0'}}</div>
                                    </div>
                                    <div style="width:100%">
                                        <div class="thin-bar-background"></div>
                                        <div class="thin-bar-overlay" :style="getThinBarStyle('bad_insurance_id')"></div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column mb-3">
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="small-head-txt">Coding</div>
                                        <div class="small-head-txt-bold">{{topIssuesChartData.datasets[0].data[1] ? topIssuesChartData.datasets[0].data[1].toLocaleString() : '0'}}</div>
                                    </div>
                                    <div style="width:100%">
                                        <div class="thin-bar-background"></div>
                                        <div class="thin-bar-overlay" :style="getThinBarStyle('coding')"></div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column mb-3">
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="small-head-txt">Late Payment</div>
                                        <div class="small-head-txt-bold">{{topIssuesChartData.datasets[0].data[2] ? topIssuesChartData.datasets[0].data[2].toLocaleString() : '0'}}</div>
                                    </div>
                                    <div style="width:100%">
                                        <div class="thin-bar-background"></div>
                                        <div class="thin-bar-overlay" :style="getThinBarStyle('late_payment')"></div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column">
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="small-head-txt">Miscellaneous</div>
                                        <div class="small-head-txt-bold">{{topIssuesChartData.datasets[0].data[3] ? topIssuesChartData.datasets[0].data[3].toLocaleString() : '0'}}</div>
                                    </div>
                                    <div style="width:100%">
                                        <div class="thin-bar-background"></div>
                                        <div class="thin-bar-overlay" :style="getThinBarStyle('miscellaneous')"></div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column mb-3">
                                    <div class="d-flex justify-content-between mb-2">
                                        <div class="small-head-txt">Under paid</div>
                                        <div class="small-head-txt-bold">{{topIssuesChartData.datasets[0].data[4] ? topIssuesChartData.datasets[0].data[4].toLocaleString() : '0'}}</div>
                                    </div>
                                    <div style="width:100%">
                                        <div class="thin-bar-background"></div>
                                        <div class="thin-bar-overlay" :style="getThinBarStyle('underpaid')"></div>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-overlay>
            <!-- first comes a dummy-card for loading bc the task embedded component already comes with it's card -->
            <b-card v-if="taskListLoading" class='summary-card shadow mb-4 pr-2' style="min-height:300px;">
                <div class="d-flex flex-row justify-content-between px-2 pb-3">
                    <div class="summary-card-header-txt">
                        Recent Tasks
                    </div>
                    <div class="avo-text-light pr-1 mt-1 cursor-pointer" @click="$store.commit('workers/setActiveTab', 'WorkerTasks')">
                        View all <b-icon icon="chevron-right"></b-icon>
                    </div>
                </div>
                <div class="spinner-container">
                    <b-spinner></b-spinner>
                </div>
            </b-card>
            <task-list @viewAllClicked="$store.commit('workers/setActiveTab', 'WorkerTasks')" v-else :taskData="taskArr" :screen="false" class="mb-4"/>
        </b-col>
    </b-row>
</b-container>
</template>

<script>
import { make_url, authenticated_request } from '../../common.js'
import Multiselect from 'vue-multiselect'
import WorkLog from '../worklog/WorkLog.vue'
import TaskList from '../tasks/TaskList.vue'
import ClaimsWorkedLineChart from '../charts/ClaimsWorkedLineChart.vue'
import HorizontalBarChart from '../charts/HorizontalBarChart.vue'
import BarChart from '../charts/BarChart.vue'
import DonutChart from '../charts/DonutChart.vue'


export default {
    name: 'WorkerSummary',
    components: {
        WorkLog,
        TaskList,
        ClaimsWorkedLineChart,
        HorizontalBarChart,
        BarChart,
        DonutChart,
        Multiselect
    },
    data(){
        return {
            // for filtering data
            practices: [],
            selectedPractice: null,
            practicesLoading: true,

            // for the worker stats
            stats: {
                avgTimeToClose: 0,
                avgClaimsWorked: 0,
                avgPaymentsCollected: 0,
                unresolvedClaims: 0,
                deniedClaims: 0,
                // second row of stats
                claimsBilledCount: 0,
                claimsBilledAmount: 0,
                claimsPaidCount: 0,
                claimsPaidAmount: 0,
                claimsOutstandingCount: 0,
                claimsOutstandingAmount: 0,
            },

            // for charts & tables
            claimsWorkedChartLoading: true,
            claimsWorkedChartData: {
                // labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                labels: [],
                datasets: [
                    {
                        borderColor: "#764FCB",
                        data: []
                    }
                ]
            },

            currentIssuesTableLoading: true,
            currentIssuesTableData: [],

            worklogListLoading: true,
            worklogArr: [],

            taskListLoading: true,
            taskArr: [],

            topIssuesChartLoading: true,
            topIssuesChartData: {
                hoverBorderWidth: 10,
                labels: [],
                datasets: [
                    {
                        backgroundColor: ["#7A9CE5", "#41B0A5", "#D191DF", "#EEC05A", "#FEA487" ],
                        data: []
                    }
                ]
            },

            claimsByStageChartLoading: true,
            claimsByStageChartData: {
                labels: ["Unopened Claim", "Open Claim", "Submitted", "Pending", "Rejected/Denied", "AR Review", "Client/Mgmt. Assistance", "Posting", "Patient Balance", "Closed"],
                datasets: [
                    {
                        backgroundColor: '#FEA487',
                        data: []
                    }
                ]
            },

            claimsByAgeChartLoading: true,
            claimsByAgeChartData: {
                labels: ["0-30 days", "31-60 days", "61-90 days", "91-120 days", "121+ days"],
                datasets: [
                    {
                        backgroundColor: '#A8C3F3',
                        data: []
                    }
                ]
            },
        }
    },
    methods: {
        getPractices(){
            const url = make_url("/practices");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
            }).then(function(response){
                if (response) {
                    vm.selectedPractice = {"name": "All Practices", "practice_id": null};
                    vm.practices = response.data.practices;
                    vm.practices.unshift(vm.selectedPractice)
                }
            }).catch(function(error){
                console.log(error.response)
            }).then(()=>{
                vm.practicesLoading = false;
            });
            return promise;
        },
        getStats() {
            const url = make_url("/reports/claims/workers/" + this.worker_id).toString();
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: {
                    practice_ids: (this.selectedPractice ? this.selectedPractice.practice_id : null)
                }
            }).then(function(response){
                if (response) {
                    const stats = response.data;
                    var avgTTCFloat = parseFloat(stats.average_time_to_close_claim);
                    var avgWorkedFloat = parseFloat(stats.average_claims_worked);
                    var avgPaymentsFloat = parseFloat(stats.average_payments_collected ? stats.average_payments_collected : 0)*100;
                    var unresolvedFloat = parseFloat(stats.unresolved_claims ? stats.unresolved_claims : 0)*100;
                    var deniedFloat = parseFloat(stats.claims_denied ? stats.claims_denied : 0)*100;

                    //  apply twoFixed() only if the stat is a decimal/frational value
                    vm.stats.avgTimeToClose =  Math.round(avgTTCFloat) !== avgTTCFloat ? avgTTCFloat.toFixed(1) : avgTTCFloat;
                    vm.stats.avgClaimsWorked =  Math.round(avgWorkedFloat) !== avgWorkedFloat ? avgWorkedFloat.toFixed(1) : avgWorkedFloat;
                    vm.stats.avgPaymentsCollected = Math.round(avgPaymentsFloat) !== avgPaymentsFloat ? avgPaymentsFloat.toFixed(1) : avgPaymentsFloat;
                    vm.stats.unresolvedClaims = Math.round(unresolvedFloat) !== unresolvedFloat ? unresolvedFloat.toFixed(1) : unresolvedFloat;
                    vm.stats.claimsDenied = Math.round(deniedFloat) !== deniedFloat ? deniedFloat.toFixed(1) : deniedFloat;

                    vm.stats.claimsBilledCount = stats.claims_billed_count;
                    vm.stats.claimsBilledAmount = parseFloat(stats.claims_billed_amount ? stats.claims_billed_amount : 0).toLocaleString();
                    vm.stats.claimsPaidCount = stats.claims_paid_count;
                    vm.stats.claimsPaidAmount = parseFloat(stats.claims_paid_amount ? stats.claims_paid_amount : 0).toLocaleString();
                    vm.stats.claimsOutstandingCount = stats.claims_outstanding_count;
                    vm.stats.claimsOutstandingAmount = parseFloat(stats.claims_outstanding_amount ? stats.claims_outstanding_amount : 0).toLocaleString();       
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },

        getClaimsWorked() {
            const url = make_url("/reports/claims/workers/" + this.worker_id + "/worked_by_date");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                practice_ids: (this.selectedPractice ? this.selectedPractice.practice_id : null)
            }).then(function(response){
                if (response) {
                    const items = response.data.claims;
                    
                    // console.log("!getting claims worked...")
                    // console.log(items)

                    // reverse array to get correct date order, ignoring dates > 7
                    var countArr = [], labelArr = [];
                    items.slice().reverse().forEach(function (item, index) {
                        if(index < 7) {
                            const dateObj = new Date(item.date + 'T00:00:00');
                            const formattedDate = new Intl.DateTimeFormat('en-US').format(dateObj);
                            labelArr.push(formattedDate);
                            countArr.push(item.count);
                        }
                    });
                    vm.claimsWorkedChartData = {
                        labels: labelArr.slice().reverse(),
                        datasets: [
                            {
                                borderColor: "#764FCB",
                                data: countArr.slice().reverse()
                            }
                        ]
                    };
                    vm.claimsWorkedChartLoading = false;
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },
        getCurrentIssueClaims() {
            const url = make_url("/reports/claims");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: {
                    worker_ids: this.worker_id,
                    issues_claims: true,
                    per_page: 6,
                    practice_ids: (this.selectedPractice ? this.selectedPractice.practice_id : null)
                }
            }).then(function(response){
                if (response) {
                    // console.log("issues claims list:");
                    // console.log(response.data);
                    vm.currentIssuesTableData = response.data.claims;
                    vm.currentIssuesTableLoading = false;
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },
        // for current issues table
        currentIssuesFields: function(){
          return [
            {
                label: 'Claim',
                key: 'claim_id',
                tdClass: 'max-width-col'
            },
            {
                label: 'Issue',
                key: 'issue_type',
            },
            'reason',
            {
                label: 'Work Status',
                key: 'work_status',
            },
          ];
        },

        getWorkLogs() {
            const vm = this;
            const url = make_url("/worklogs");
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: {
                    'source_workers': vm.worker_id,
                    'per_page': 7,
                }
            }).then(function(response){
                const items = response.data.worklogs;
                vm.worklogArr = items;
                vm.worklogListLoading = false;
            }).catch(function(error){
                console.log(error.response.data)
            });
            return promise;
        },

        getTasks() {
            const vm = this;
            const url = make_url("/reports/claims/tasks");
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: {
                    'worker_id': vm.worker_id,
                    'per_page': 10,
                    'state': 0,
                }
            }).then(function(response){
                const items = response.data;
                vm.taskArr = items.tasks;
                vm.taskListLoading = false;
            }).catch(function(error){
                console.log(error.response.data)
            });
            return promise;
        },

        // for top claim issues chart
        getTopClaimIssues() {
            const url = make_url("/reports/claims/issues");
            const vm = this;

            const promise = authenticated_request({
                method: "get",
                url: url,
                params: {
                    worker_ids: this.worker_id,
                    practice_ids: (this.selectedPractice ? this.selectedPractice.practice_id : null)
                }
            }).then(function(response){
                if (response) {
                    const issues = response.data;
                    vm.topIssuesChartData = {
                        hoverBorderWidth: 10,
                        labels: Object.keys(issues),
                        datasets: [
                            {
                                backgroundColor: ["#7A9CE5", "#41B0A5", "#D191DF", "#EEC05A", "#FEA487" ],
                                data: Object.values(issues)
                            }
                        ]
                    }
                    vm.topIssuesChartLoading = false;
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },
        getThinBarStyle(range){
            var styleObject = {
                backgroundColor: 'transparent',
                width: '0px'
            }
            var rangeAmount = 0;
            switch(range){
                case "bad_insurance_id":
                    rangeAmount = this.topIssuesChartData.datasets[0].data[0];
                    styleObject.backgroundColor = '#7A9CE5'
                    break;
                case "coding":
                    rangeAmount = this.topIssuesChartData.datasets[0].data[1];
                    styleObject.backgroundColor = '#41B0A5'
                    break;
                case "late_payment":
                    rangeAmount = this.topIssuesChartData.datasets[0].data[2];
                    styleObject.backgroundColor = '#D191DF'
                    break;
                case "miscellaneous":
                    rangeAmount = this.topIssuesChartData.datasets[0].data[3];
                    styleObject.backgroundColor = '#EEC05A'
                    break;
                case "underpaid":
                    rangeAmount = this.topIssuesChartData.datasets[0].data[4];
                    styleObject.backgroundColor = '#FEA487'
                    break;
                default:
                    break
            }
            if(rangeAmount != 0) {
                var percent = ((rangeAmount/this.totalIssuesClaims) * 100).toFixed(0);
                styleObject.width = percent + '%';
            }
            return styleObject;
        },
        getClaimsByStage(){
            const url = make_url("/reports/claims/workers/state");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: {
                    worker_ids: this.worker_id,
                    practice_ids: (this.selectedPractice ? this.selectedPractice.practice_id : null)
                }
            }).then(function(response){
                if (response) {
                    const items = response.data.workers[0];
                    vm.claimsByStageChartData = {
                        labels: ["Unopened Claim", "Open Claim", "Submitted", "Pending", "Rejected/Denied", "AR Review", "Client/Mgmt. Assistance", "Posting", "Patient Balance", "Closed"],
                        datasets: [
                            {
                                backgroundColor: '#FEA487',
                                data: [
                                    items.unopened || 0,
                                    items.opened || 0,
                                    items.submitted || 0,
                                    items.pending || 0,
                                    items.rejected_denied || 0,
                                    items.ar_review || 0,
                                    items.assistance || 0,
                                    items.posting || 0,
                                    items.patient_balance || 0,
                                    items.closed || 0
                                ]
                            }
                        ]
                    };
                    vm.claimsByStageChartLoading = false;              
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        },
        getClaimsByAge(){
            const url = make_url("/reports/claims/workers/age");
            const vm = this;
            const promise = authenticated_request({
                method: "get",
                url: url,
                params: {
                    worker_ids: this.worker_id,
                    practice_ids: (this.selectedPractice ? this.selectedPractice.practice_id : null)
                }
            }).then(function(response){
                if (response) {
                    const items = response.data.workers[0];
                    
                    vm.claimsByAgeChartData.datasets[0].data = [
                        items.claim_count_0_30_days,
                        items.claim_count_31_60_days,
                        items.claim_count_61_90_days,
                        items.claim_count_91_120_days,
                        items.claim_count_121_days
                    ];
                    vm.claimsByAgeChartData = {
                        labels: ["0-30 days", "31-60 days", "61-90 days", "91-120 days", "121+ days"],
                        datasets: [
                            {
                                backgroundColor: '#A8C3F3',
                                data: [
                                    items.claim_count_0_30_days,
                                    items.claim_count_31_60_days,
                                    items.claim_count_61_90_days,
                                    items.claim_count_91_120_days,
                                    items.claim_count_121_days
                                ]
                            }
                        ]
                    };
                    vm.claimsByAgeChartLoading = false;
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;
        }

    },
    computed: {
        worker: function(){
            return this.$store.state.workers.selected_worker;
        },
        worker_id: function(){
            return this.$store.state.workers.selected_worker.worker_id;
        },
        refreshTasks: function(){
            return this.$store.state.workers.reloadTasks
        },
        totalIssuesClaims: function(){
            return this.topIssuesChartData.datasets[0].data.reduce((a, b) => a + b, 0)
        },
    },
    watch: {
        refreshTasks(newState){
            if(newState){
                this.getTasks();
                this.$store.commit("workers/tasksRefreshed");
            } 
        },
        selectedPractice(){
            this.claimsWorkedChartLoading = true;
            this.currentIssuesTableLoading = true;
            this.topIssuesChartLoading = true;
            this.claimsByStageChartLoading = true;
            this.claimsByAgeChartLoading = true;
            this.getStats();
            this.getClaimsWorked();
            this.getWorkLogs();
            this.getTopClaimIssues();
            this.getClaimsByStage();
            this.getClaimsByAge();
            this.getCurrentIssueClaims();
        }
    },
    mounted() {
        this.getPractices();
        this.getTasks();
        this.getWorkLogs();
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* increasing b-containers max-width for larger screens */
@media only screen and (min-width : 1200px) {
   .container { max-width: 1400px; } 
}
.stat-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 7px 10px 5px 17px;
    min-width: 180px;
    /*max-width: 200px;*/
    height: 80px;
    border: 1px solid #E2E4EB;
    border-radius: 5px;
}
.stat-unit-text {
    font-size:20px;
    color:#929BAA;
    font-weight:400;
}
.summary-card {
    border-radius: 5px 5px 5px 5px;
    background: white;
    border: 1px solid #E2E4EB;
}
.summary-card-table {
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #E2E4EB;
}
.summary-card-table .card-body{
  padding: 1rem 0px 0px 0px !important;
}
.chart-container {
    position:relative;
    display: flex; 
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow:visible !important;
}
.bar-chart-container {
    display: flex; 
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 16px 8px 24px 0;
}
.spinner-container {
    position: absolute;
    top:50%;
    left:45%
}
/* styles for custom top claim issues progress bar-type graph */
.thin-bar-background {
    background-color:#E7EAEE;
    height:7px;
    border-radius:4px;
}
.thin-bar-overlay {
    height:7px;
    border-radius:4px;
    margin-top:-7px;
}
.absolute-centering {
    position: absolute;
    /* move the element half way across the screen */
    left: 50%;
    /* allow the width to be calculated dynamically */
    width: auto;
    /* then move the element back again using a transform */
    transform: translateX(-50%);
}
.claims-billed-text {
    font-size: 36px;
    font-weight: 600;
    color: var(--blue);
}
.claims-paid-text {
    font-size: 36px;
    font-weight: 600;
    color: #67B765;
}
.claims-outstanding-text {
    font-size: 36px;
    font-weight: 600;
    color: #FE816B;
}
.avo-medium-text {
    font-weight: 500;
    font-size: 16px;
    color: #38455D
}
.small-head-txt {
    font-size:14px;
    color:#38455D;
}
.small-head-txt-med {
    font-weight:500;
    font-size:14px;
    color:#38455D;
}
.small-head-txt-bold {
    font-weight:600;
    font-size:14px;
    color:#38455D;
}
</style>